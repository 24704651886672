import React, {useEffect} from 'react'
import gsap, {Power2, Expo} from 'gsap'
import '../styles/Intro.css'

const Intro = () => {
    useEffect(() => {
        gsap.fromTo(".introWrapper p", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 3, ease: Expo.easeInOut})
        gsap.fromTo(".introWrapper div", {opacity: 0, y: 100}, {
            opacity: 1,
            y: 0,
            duration: 2,
            ease: Expo.easeInOut
        }).delay(1)
    })
    const handleClick = () => {
        gsap.to(".introWrapper p", {opacity: 0, y: 100, duration: 3, ease: Expo.easeInOut})
        gsap.to(".introWrapper div", {opacity: 0, y: 100, duration: 2, ease: Expo.easeInOut})
        gsap.to(".introWrapper", {opacity: 0, display: "none", duration: 2, ease: Power2.easeInOut})
        setTimeout(() => {
            window.location = '/home'
        }, 2000)
    }
    return (
        <section onClick={handleClick} className={'introWrapper'}>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto commodi, culpa debitis dolor
                eaque eligendi eum fuga itaque non nostrum obcaecati omnis praesentium quidem quis quos ratione
                temporibus vel.</p>
            <div>
                <span className={"tapToContinue"}>Tap to continue</span>
                <span className={"accentLine"}/>
            </div>
        </section>
    )
}
export default Intro