import React, {useRef, useEffect} from 'react'
import '../styles/Header.css'
import Button from "./Button";
import gsap from "gsap"
import {ArrowOpacity, HeaderScrolled, Intro} from "./Animate";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

function Header({handleOpenMenu}) {
    const isMobile = useMediaQuery("(max-width: 769px)")
    let header = useRef(null);
    let arrow = useRef(null);
    const {t} = useTranslation('', {keyPrefix: 'header'});
    useEffect(() => {
        Intro(header)
        HeaderScrolled(header)
        ArrowOpacity(arrow)
    }, [])
    const handleScrollToTop = () => gsap.to(window, {duration: 2, scrollTo: 0})
    return (
        <header ref={(el) => (header = el)}>
            <div className="logo">
                Codezia
            </div>
            <div onClick={handleScrollToTop} ref={(el) => (arrow = el)} className="arrow"/>
            {!isMobile ? (<Button onClick={handleOpenMenu} color={'white'}>{t('contact')}</Button>) : null}
        </header>
    )
}

export default Header