import React, {useEffect, useRef} from 'react'
import ColoredText from "./ColoredText";
import '../styles/Banner.css'
import {CopyrightIntro, HeadingIntro} from "./Animate";
import {userStore} from "../stores/user";
import {useTranslation} from "react-i18next";

const languageMap = {
    "de-DE": "German",
    "en-EN": "English"
}

const Banner = ({isIntroOpen}) => {
    let headingRef = useRef(null)
    let copyrightRef = useRef(null)
    let languageRef = useRef(null)
    const {t} = useTranslation('', {keyPrefix: "header.banner"})
    const {toggleLanguage, language} = userStore()
    useEffect(() => {
        HeadingIntro(headingRef)
        CopyrightIntro(copyrightRef, languageRef)
    }, [])
    return (
        <div>
            <h1 ref={el => headingRef = el} className={"headingBanner"}>{t('part1')}<ColoredText
                sx={{fontStyle: 'italic'}} color="#F7996E">{t("designers")}</ColoredText>, <ColoredText
                color={'#57ffe1'}
                sx={{fontStyle: 'italic'}}>{t("developers")}</ColoredText> {t("and")} <ColoredText
                color={'#fc6398'} sx={{fontStyle: 'italic'}}>{t("visionaries")}</ColoredText>{t("part2")}</h1>
            <div ref={el => copyrightRef = el} className={"copyright"}>
                <div>
                    <p>Codezia © 2022</p>
                    <a ref={el => languageRef = el}
                       onClick={() => toggleLanguage(language)}>{language === "de-DE" ? languageMap["en-EN"] : languageMap["de-DE"]}</a>
                </div>
                <span/>
            </div>
        </div>
    )
}

export default Banner