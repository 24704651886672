import React, {useEffect, useRef, useState} from 'react'
import '../styles/Inquiry.css'
import {GradientBlob} from "../components";
import gsap, {Power2} from "gsap";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

const Inquiry = ({setInquiryProps}) => {
    const [hoverEffect, setHoverEffect] = useState('')
    const [overallOpacity, setOverallOpacity] = useState('1')
    const isMobile = useMediaQuery('(max-width: 769px)');
    let headingTrigger = useRef()
    const opacity = {opacity: overallOpacity}
    const { t } = useTranslation('', { keyPrefix: 'inquiries' });
    const mouseOverEventHelper = (hoverEffect) => {
        setOverallOpacity("0.3")
        setHoverEffect(hoverEffect)
    }
    const mouseLeaveEventHelper = () => {
        setOverallOpacity("1")
        setHoverEffect("initial")
    }

    useEffect(() => {
        gsap.fromTo(headingTrigger, {opacity: 0, x: -100}, {
            scrollTrigger: headingTrigger,
            opacity: 1,
            x: 0,
            duration: 1
        })
        gsap.utils.toArray(".inquiryWrapper").forEach((curr, index) => gsap.fromTo(curr, {opacity: 0, x: -300}, {
            scrollTrigger: headingTrigger,
            opacity: 1, duration: 1, x: 0, delay: (1 + index * 0.2),
            ease: Power2.easeInOut
        }))
    }, [])
    const handleOpenInquiry = key => {
        setInquiryProps({type: key, open: true})
    }
    return (
        <section className={'inquiriesContainer'}>
            <div className={'inquiryHeading'}>
                <div ref={el => headingTrigger = el}>
                    <h2>{t("inquiriesSubtitle")}</h2>
                    <h3>{t("inquiriesTitle")}</h3>
                </div>
                <GradientBlob animate={(el, position) =>
                    gsap.fromTo(el, {
                            opacity: 0,
                            y: 800,
                            x: -1000,
                            ease: Power2.easeInOut,
                            filter: "grayscale(100%)"
                        },
                        {
                            scrollTrigger: {
                                trigger: el,
                                start: "top-=300px bottom",
                                end: "bottom bottom-=400px",
                            },
                            opacity: .4,
                            y: position.y,
                            x: position.x,
                            duration: 2,
                            filter: "blur(64px)"
                        })} hoverEffect={hoverEffect} position={{y: "15%", x: isMobile ? "100%" : "0px"}}/>
            </div>
            <div style={opacity} onMouseOver={(el) => mouseOverEventHelper("purpleSilver")}
                 onMouseLeave={mouseLeaveEventHelper}
                 onClick={() => handleOpenInquiry("websiteDevelopment")} className={'inquiryWrapper'}>
                <span className={'inquiryIndex'}>01</span>
                <div id={"triggerInquiry"} className={'inquiryContentWrapper'}>
                    <h3>{t("websiteDevelopment")}</h3>
                    <code>+</code>
                </div>
            </div>
            <div style={opacity} onMouseOver={() => mouseOverEventHelper("blueTurqoise")}
                 onMouseLeave={mouseLeaveEventHelper}
                 onClick={() => handleOpenInquiry("ecommerceDevelopment")}
                 className={'inquiryWrapper'}>
                <span className={'inquiryIndex'}>02</span>
                <div className={'inquiryContentWrapper'}>
                    <h3>{t("ecommerceDevelopment")}</h3>
                    <code>+</code>
                </div>
            </div>
            <div style={opacity} onMouseOver={() => mouseOverEventHelper("violetPink")}
                 onMouseLeave={mouseLeaveEventHelper} onClick={() => handleOpenInquiry("appDevelopment")}className={'inquiryWrapper'}>
                <span className={'inquiryIndex'}>03</span>
                <div className={'inquiryContentWrapper'}>
                    <h3>{t("appDevelopment")}</h3>
                    <code>+</code>
                </div>
            </div>
            <div style={opacity} onMouseOver={() => mouseOverEventHelper("whiteMagenta")}
                 onMouseLeave={mouseLeaveEventHelper} onClick={() => handleOpenInquiry("digitalTransformation")} className={'inquiryWrapper'}>
                <span className={'inquiryIndex'}>04</span>
                <div className={'inquiryContentWrapper'}>
                    <h3>{t("digitalTransformation")}</h3>
                    <code>+</code>
                </div>
            </div>
            <div style={opacity} onMouseOver={() => mouseOverEventHelper("greenYellow")}
                 onMouseLeave={mouseLeaveEventHelper} onClick={() => handleOpenInquiry("design")} className={'inquiryWrapper'}>
                <span className={'inquiryIndex'}>05</span>
                <div className={'inquiryContentWrapper'}>
                <h3>{t("design")}</h3>
                    <code>+</code>
                </div>
            </div>
        </section>
    )
}
export default Inquiry