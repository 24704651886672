import gsap from "gsap"

export const Intro = el => {
    gsap.fromTo(el, {
        opacity: 0,
        y: -100,
        duration: 1,
        ease: "back",
    }, {
        opacity: 1,
        y: 0,
    }).delay(1.5);
};
export const BlobAnimate = (el, position) => {
    gsap.fromTo(el, {
            opacity: 0,
            y: 600,
            x: -500,
            ease: "back",
            filter: "grayscale(100%)"
        },
        {
            opacity: .4,
            y: position.y,
            x: position.x,
            duration: 4,
            filter: "blur(64px)"
        });
};
export const HeadingIntro = el => {
    gsap.fromTo(el, {
        opacity: 0,
        x: -500,
        ease: "in",
        filter: "grayscale(100%)"
    }, {
        opacity: 1,
        x: 0,
        duration: 1,
        filter: "none"
    }).delay(1.5);
};
export const CopyrightIntro = (spanRef, languageRef) => {
    gsap.fromTo(spanRef, {
        opacity: 0,
        width: 0,
        duration: 1,
        x: 200,
    }, {
        delay: 2,
        opacity: 1,
        width: "100%",
        x: 0,
    })
    gsap.to(languageRef, {opacity: 1, duration: 1, delay: 3})
}

export const HeaderScrolled = el => {
    gsap.fromTo(el, {}, {
        scrollTrigger: {
            onEnter: () => {
                el.className = "inActiveHeader"
            },
            onLeaveBack: () => {
                el.className = ""
            },
            trigger: el.current,
            start: "+=100px",
        },
    });
};
export const ArrowOpacity = el => {
    gsap.fromTo(el, {}, {
        scrollTrigger: {
            onEnter: () => {
                el.style.opacity = "1"
            },
            onLeaveBack: () => {
                el.style.opacity = "0"
            },
            trigger: el.current,
            start: "+=400px",
        },
    });
};
export const ImageScroll = (imgRef, blurBox) => {
    console.log(!window.matchMedia('(max-width: 769px)') ? 0 : 1)
    gsap.fromTo(imgRef, {
        opacity: 0,
        duration: 1,
        width: "30vw",
        y: 500,
    }, {
        opacity: 1, width: "100vw", duration: 2, y: 0, scrollTrigger: {
            trigger: imgRef.current,
            start: "top top",
            end: "bottom top",
            scrub: 1,
            snap: !window.matchMedia('(max-width: 769px)') ? 0 : 1
        }
    })
    gsap.fromTo(blurBox, {
        backdropFilter: "blur(1px)",
    }, {
        backdropFilter: "blur(4px) brightness(.3)",   duration: 2, scrollTrigger: {
            trigger: imgRef.current,
            scrub: true,
            start: "top+=300 top",
            end: "+=550",
        }
    })
};
export const WWSFIntro = (el) =>{
    gsap.fromTo(el, {opacity: 0, x: -500}, {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger:  el.current,
    }).delay(1)
}