import React, {useRef, useEffect} from 'react'
import '../styles/SecondSection.css'
import gsap from 'gsap'
import {ImageScroll} from "../components/Animate";
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {useTranslation} from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)


const SecondSection = () => {
    const {t} = useTranslation('',{keyPrefix: "aboutUs"})
    let imgRef = useRef(null)
    let blurBox = useRef(null)
    useEffect(() => {
        ImageScroll(imgRef, blurBox)
    }, [])
    return (
        <section className={'secondSectionWrapper'}>
            <div className={'secondSectionImage'} ref={el => imgRef = el}>
                <div ref={el => blurBox = el} className={"blurBox"}>
                    <div className={'getToKnowUs'}>
                        <div>
                            <h2>{t("subTitle")}</h2>
                            <h3>{t("title")}</h3>
                        </div>
                        <div className={"aboutUsParagraphs"}>
                            <p>{t("paragraph")}</p>
                            <br/>
                            <p>{t("annotation")}</p>
                        </div>
                        <div className={"hashtags"}>
                            <span>#Accessibility</span>
                            <span>#DesignThinking</span>
                            <span>#TailoredSolutions</span>
                            <span>#UserExperience</span>
                            <span>#UserControl</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SecondSection
