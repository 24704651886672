import React from 'react'
import {Button} from "./index";
import {useTranslation} from "react-i18next";

const ContactModal = ({menuRef, handleCloseMenu, btnRef}) => {
    const {t} = useTranslation('', {keyPrefix: "contactModal"})
    return (
        <div ref={menuRef} className="menu">
            <div className="background">{t('contact')}</div>
            <div ref={btnRef} className="exit"><Button onClick={handleCloseMenu}
                                                       color={'white'}>{t('back')}</Button></div>
            <div className="menu-container">
                <ul className="options">
                    <li className="nav"><a href="#" className="nav-link">{t('we')}</a></li>
                    <li className="nav"><a href="#" className="nav-link">{t('create')}</a></li>
                    <li className="nav"><a href="#" className="nav-link">{t('excellence')}</a>
                    </li>
                </ul>
            </div>
            <div className="menu-container right">
                <div className="information">
                    <p className="title location">{t('location')}</p>
                    <p className="description">BERLIN/{t('germany')}</p>

                </div>
                <div className="information">
                    <p className="title">{t('email')}</p>
                    <p className="description">CODEZIADEV@GMAIL.COM</p>
                </div>
                <div className="information">
                    <p className="title">{t('phoneNumber')}</p>
                    <p className="description">+49 *** *** ****</p>
                </div>
                <div className="information">
                    <p className="title">{t('socials')}</p>
                    <div className="social-medias">
                        <a href="#" className="social-media">tw</a>
                        <a href="#" className="social-media">li</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactModal