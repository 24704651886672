import create from 'zustand'
import i18n from "i18next";

export const userStore = create((set) => ({
    language: "de-DE",
    isDesktopDevice: true,
    setIsDesktop: (isDesktop)=> set({isDesktopDevice: isDesktop}),
    toggleLanguage: (currLanguage) => {
        if(!!currLanguage){
            if (currLanguage==="de-DE")  {
                localStorage.setItem('i18nextLng', "en-EN")
                set({language: "en-EN"})
                i18n.changeLanguage("en-EN").then(r => console.log(r));
                return
            }else {
                set({language: "de-DE"})
                localStorage.setItem('i18nextLng', "de-DE")
                i18n.changeLanguage("de-DE").then(r => console.log(r));
            }
        }

    },
}))