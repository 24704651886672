import React from 'react'
import styles from "../../../styles/Contact.module.css";
import shop from "../../../assets/contact/shop-svgrepo-com.svg";
import {useTranslation} from "react-i18next";

const Size = ({setSize, size}) => {
    const {t} = useTranslation('', {keyPrefix:"inquiries.forms.general.scope"})
    return (
        <div className={styles.wrapper}>
            <button
                className={styles.size} onClick={() => setSize("small")}
                style={size !== null ? {filter: "invert(1)"} : {}}>
                <img  alt='shop' src={shop} />
                <span>{t("small")}</span>
            </button>
            <button
                className={styles.size} onClick={() => setSize("medium")}
                style={size !== "small" && size !== null ? {filter: "invert(1)"} : {}}>
                <img alt='shop' src={shop} />
                <span>{t("medium")}</span>
            </button>
            <button
                className={styles.size} onClick={() => setSize("big")}
                style={size !== "medium" && size !== "small" && size !== null ? {filter: "invert(1)"} : {}}>
                <img alt='shop' src={shop} />
                <span>{t("big")}</span>
            </button>
        </div>
    )
}
export default Size