import React from 'react'
import styles from "../../../styles/Contact.module.css";
import {useTranslation} from "react-i18next";

const ContactInformation = ({product, size, information, handlePreviousSlide}) => {
    const {t} = useTranslation('', {keyPrefix: "inquiries.forms.general.contact"})
    const [tGeneral] = useTranslation('', {keyPrefix: "inquiries.forms.general"})
    return (
            <form className={styles.form} name="contact" method="post">
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                    <input type="text" name="product" readOnly value={product}/>
                    <input type="text" name="size" readOnly value={size}/>
                    <textarea name="informations" readOnly value={information}/>
                </div>
                <div className={styles.flex}>
                    <label className={styles.label}>{t('name')}<input required className={styles.input} type="text" name="name"/></label>
                    <label className={styles.label}>{t('email')}<input required className={styles.input} type="email" name="email"/></label>
                    <label className={styles.label}>{t('phoneNumber')}<input required className={styles.input} type="tel" name="phone"/></label>
                </div>
                <div>
                    <button onClick={handlePreviousSlide}>{tGeneral('previous')}</button>
                    <button className={styles.finalSubmitButton} type="submit">{tGeneral('send')}</button>
                </div>
            </form>
    )
}
export default ContactInformation