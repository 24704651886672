import React from 'react'
import Banner from "../components/Banner";
import GradientBlob from "../components/GradientBlob";

const HeadingSection = () => {
    return (
        <section className={'headingSectionWrapper'}>
            <Banner/>
            <GradientBlob/>
        </section>
    )
}

export default HeadingSection