import React, {useEffect, useRef, useState} from "react"
import '../styles/Main.css'
import gsap, {Power2} from "gsap"
import HeadingSection from "./HeadingSection";
import '../styles/Menu.css'
import SecondSection from "./SecondSection";
import {Header,  Experties} from "../components";
import Inquiry from "./Inquiry";
import InquiryController from "./InquiryController";
import Footer from "./Footer";
import ContactModal from "../components/ContactModal";

const Main = () => {
    const [menuTween] = useState(gsap.timeline({paused: true}))
    let menuRef = useRef(null)
    let btnRef = useRef(null)
    const [inquiryProps, setInquiryProps] = useState({type:"websiteDevelopment", open:false})
    useEffect(() => {
        menuTween.to(menuRef.current, {opacity: 1, duration: 1.5, right: 0, ease: Power2.easeInOut})
            .to(
                ".nav",
                {
                    opacity: 1,
                    marginBottom: 0,
                    duration: 1,
                    ease: Power2.easeInOut,
                    stagger: 0.3,
                },
                ">-0.5"
            ).to(
            ".information",
            {
                opacity: 1,
                duration: 1,
                ease: Power2.easeInOut,
                stagger: 0.2,
            },
            ">-0.5"
        ).to(
            btnRef.current,
            {opacity: 1, right: 40, duration: .5}
        )
    }, [menuTween])

    const handleOpenMenu = () => {
        menuTween.play()
        document.body.style.overflow = "hidden"
    }
    const handleCloseMenu = () => {
        menuTween.timeScale(2.5);
        menuTween.reverse();
        setTimeout(() => {
            document.body.style.overflow = ""
        }, 1700)
    }

    return (
        <>
            <Header handleOpenMenu={handleOpenMenu}/>
            <main>
                <HeadingSection/>
                <SecondSection/>
                <Experties/>
                <Inquiry setInquiryProps={setInquiryProps}/>
                <Footer/>
            </main>
            <InquiryController setInquiryProps={setInquiryProps} close={()=> setInquiryProps(prev=> ({...prev, open: false}))} inquiryProps={inquiryProps}/>
            <ContactModal btnRef={btnRef} menuRef={menuRef} handleCloseMenu={handleCloseMenu}/>
        </>
        )
}
export default Main