import React, {useEffect, useRef} from 'react'
import '../styles/Footer.css'
import gsap, {Power2} from 'gsap'
import {useTranslation} from "react-i18next";

const Footer = ()=> {
    const footerRef = useRef(null)
    const {t} = useTranslation('', {keyPrefix: "footer"})
    useEffect(()=>{
        gsap.fromTo(footerRef, {
            opacity: 0,
            y: 500,
        }, {
            opacity: 1, duration: 1, y: 0, scrollTrigger: {trigger:footerRef.current}
        })
        gsap.utils.toArray(footerRef.current.children).forEach((curr, index) => gsap.fromTo(curr, {opacity: 0, y: 500}, {
            scrollTrigger: {trigger:footerRef.current},
            opacity: 1, duration: .75, y: 0, delay: ( index * 0.075),
            ease: Power2.easeInOut
        }))
    },[])
    return (
        <footer ref={footerRef}>
            <div className={'col col1'}>
                <h2>Codezia</h2>
                <p>{t('inspirationalArt')}</p>
            </div>
            <div className={'col col2'}>
                <h3>{t('abstract')}</h3>
                <ul className={'footerList'}>
                    <li>lorem!</li>
                    <li>ispum!</li>
                    <li>dolor!</li>
                    <li>sit!</li>
                    <li>amet!</li>
                </ul>
                <h3  className={'addOn'}>{t("abstract2")}</h3>
            </div>
            <div className={'col col3'}>
                <h3>{t("social")}</h3>
                <ul className={'footerList'}>
                    <li>LINKEDIN</li>
                    <li>TWITTER</li>
                    <li>FACEBOOK</li>
                    <li>BEHANCE</li>
                </ul>
                <h3  className={'addOn'}>{t("followUs")}</h3>
            </div>
            <div className={'col col4'}>
                <h3>{t("law")}</h3>
                <ul className={'footerList'}>
                    <li>IMPRESSUM</li>
                    <li>PRIVACY POLICY</li>
                </ul>
                <h3 className={'addOn'}>:D</h3>
            </div>
    </footer>)
}

export default Footer