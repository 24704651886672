import React, {useEffect, useRef, useState} from 'react'
import '../styles/InquiryForm.css'
import gsap, {Power2} from "gsap";
import ContactInformation from "../components/Forms/components/ContactInformation";
import Size from "../components/Forms/components/Size";
import styles from "../styles/Contact.module.css";
import {useTranslation} from "react-i18next";


const InquiryController = ({close, inquiryProps = {open: false, type: "websiteDevelopment"}}) => {
    const [slide, setSlide] = useState(0)
    const [size, setSize] = useState(null)
    const [information, setInformation] = useState('')
    const [inquiryTween] = useState(gsap.timeline({paused: true}))
    let inquiryContainer = useRef(null)
    let formWrapper = useRef(null)
    let closeButtonRef = useRef(null)
    const {t} = useTranslation('', {keyPrefix: "inquiries.forms"})
    const [tGeneral] = useTranslation('', {keyPrefix: "inquiries.forms.general"})
    useEffect(() => {
        inquiryTween.to(inquiryContainer, {opacity: 1, duration: 1, right: 0, ease: Power2.easeInOut})
    }, [inquiryTween])
    useEffect(() => {
        if (inquiryProps.open) {
            inquiryTween.play()
            document.body.style.overflow = "hidden"
        }
    }, [inquiryProps])

    const resetForm = () => {
        setSlide(0)
        setSize(null)
        setInformation("")
    }
    const handleReturn = () => {
        inquiryTween.reverse();
        setTimeout(() => {
            document.body.style.overflow = ""
        }, 1700)
        close()

        setTimeout(() => {
            resetForm()
        }, 2500)
    }
    const changeSlideAnimation = (switchStatement, shouldReverse = false) => {
        if (shouldReverse) {
            gsap.timeline({paused: true})
                .to(formWrapper, {x: 400, opacity: 0, duration: 1, ease: Power2.easeInOut}).to(formWrapper, {
                x: -200,
                opacity: 0,
                duration: 0,
            }).play()
        } else {
            gsap.timeline({paused: true})
                .to(formWrapper, {x: -200, opacity: 0, duration: 1, ease: Power2.easeInOut}).to(formWrapper, {
                x: 400,
                opacity: 0,
                duration: 0,
            }).play()
        }
        setTimeout(async () => {
            await switchStatement()
            gsap.to(formWrapper, {x: 0, opacity: 1, duration: 1, ease: Power2.easeInOut})
        }, 1000)
    }
    const handleNextSlide = () => changeSlideAnimation(() => setSlide(prev => prev + 1))
    const handlePreviousSlide = () => {
        if (slide === 0) return
        changeSlideAnimation(() => setSlide(prev => prev - 1), true)
    }

    const formComponents = {
        intro: translationKey => ({
            title: t(`${translationKey}.title`),
            subTitle: t(`${translationKey}.subTitle`),
            paragraph: tGeneral('confidentialityDescription'),
            nextButton: tGeneral("begin")
        }),
        scope: {
            title: tGeneral("scope.title"),
            subTitle: tGeneral("scope.subTitle"),
            specialComponent: (<Size size={size} setSize={setSize}/>),
            nextButton: tGeneral('next')
        },
        description: {
            title: tGeneral("description.title"),
            subTitle: tGeneral("description.subTitle"),
            textArea: (<textarea
                className={styles.textarea}
                id="message"
                name="message"
                placeholder={tGeneral("description.placeHolder")}
                rows={4}
                value={information}
                onChange={(e) => setInformation(e.target.value)}
            />),
            nextButton: tGeneral("next")
        },
        contactInformation: product => (
            {
                title: tGeneral('contact.title'),
                subTitle: tGeneral('contact.subTitle'),
                nextButton: tGeneral("send"),
                specialComponent: (
                    <ContactInformation product={product} handlePreviousSlide={handlePreviousSlide}
                                        information={information} size={size}/>)
            }
        )
    }

    const formConfig = {
        websiteDevelopment: [formComponents["intro"]("websiteDevelopment"), formComponents["scope"], formComponents["description"], formComponents["contactInformation"]("Website Development")],
        ecommerceDevelopment: [formComponents["intro"]("ecommerceDevelopment"), formComponents["scope"], formComponents["description"], formComponents["contactInformation"]("Ecommerce Development")],
        appDevelopment: [formComponents["intro"]("appDevelopment"), formComponents["scope"], formComponents["description"], formComponents["contactInformation"]("Mobile Application Development")],
        digitalTransformation: [formComponents["intro"]("digitalTransformation"),  formComponents["description"], formComponents["contactInformation"]("Ecommerce Development")],
        design: [formComponents["intro"]("design"),  formComponents["description"], formComponents["contactInformation"]("UX/UI Design")],

    }


    return (
        <div ref={el => inquiryContainer = el} className={"inquiryLayout"}>
            <button ref={closeButtonRef} className={'closeInquiryBtn'} onClick={handleReturn}>{tGeneral('close')}</button>
            <div ref={el => formWrapper = el} className={'formContainer'}>
                {formConfig[inquiryProps.type][slide].title ? (
                    <h1>{formConfig[inquiryProps.type][slide].title}</h1>
                ) : null}
                {formConfig[inquiryProps.type][slide].subTitle ? (
                    <h2>{formConfig[inquiryProps.type][slide].subTitle}</h2>
                ) : null}
                {formConfig[inquiryProps.type][slide].paragraph ? (
                    <p>{formConfig[inquiryProps.type][slide].paragraph}</p>
                ) : null}
                {formConfig[inquiryProps.type][slide].specialComponent ? (
                    formConfig[inquiryProps.type][slide].specialComponent
                ) : null}
                {formConfig[inquiryProps.type][slide].textArea ? (
                    formConfig[inquiryProps.type][slide].textArea
                ) : null}
                <div className={'buttonWrapper'}>
                    <div>
                    {slide !== 0 && slide !== formConfig[inquiryProps.type].length - 1 ? (
                        <button onClick={handlePreviousSlide}>{tGeneral("previous")}</button>
                    ) : null}
                    {slide !== formConfig[inquiryProps.type].length - 1 ? (
                        <button
                            style={slide !== 0 && slide !== formConfig[inquiryProps.type].length - 1 ? {marginLeft: "1rem"} : {}}
                            onClick={handleNextSlide}>{formConfig[inquiryProps.type][slide].nextButton}</button>
                    ) : null}
                    </div>
                {(slide + 1) !== formConfig[inquiryProps.type].length ? (
                    <p className={'currentSlideCount'}>{slide + 1}/{formConfig[inquiryProps.type].length}</p>

                ) : null}
                </div>
            </div>
        </div>
    )
}
export default InquiryController