import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-extraneous-dependencies
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// File resources
export const DEFAULT_LOCALE_MAP = {
    en: 'en-GB',
    de: 'de-DE',
};

const getTranslationPath = (locale, ns) => {
    const lng = locale.map((localeItem) => localeItem.split('-')[0]);
    return `/locales/${lng}/${ns}.json`;
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // resources,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            // cache user language on
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            // cookieMinutes: 10,
            // cookieDomain: 'myDomain',

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,
        },
        // lng: 'en-GB',
        whitelist: ['en', 'de'],
        // keySeparator: false, // we do not use keys in form messages.welcome
        react: {
            wait: true,
        },
    }).then(r => console.log(r));

// setTimeout(()=>{
//     i18n.changeLanguage('de');
//
// }, 2000)

export default i18n;
