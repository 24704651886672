import React from 'react'
import '../styles/Button.css'
const Button = ({color = "", children, href="#", onClick} ) => {

    return (
        <a onClick={onClick} style={{ color:color }} href={href}>
            {new Array(4).fill("").map((curr, index)=>(<span key={index} style={{backgroundColor: color}}/>))}
            {children}
        </a>
    )

}

export default Button