import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './index.css'
import Main from "./containers/Main"
import {useEffect, useState} from "react";
import {userStore} from "./stores/user";
import Intro from "./components/Intro";

const debounce = (fn, ms) => {
    let timer
    console.log('debounced')
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this)
        }, ms)
    };
}

function App() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }})

    const {setIsDesktop} = userStore()
    useEffect(()=>{
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) setIsDesktop(false)
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) setIsDesktop(true)
        else setIsDesktop(true)
    },[])
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="home" element={<Main/>}/>
                    <Route path="*" element={<Intro/>}/>
                </Routes>
            </BrowserRouter>
        );
}

export default App;
