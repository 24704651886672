import React, {useEffect, useRef} from 'react'
import styles from '../styles/Expertise.module.css'
import {GradientBlob} from "../components";
import gsap, {Power2} from 'gsap'
import {useTranslation} from "react-i18next";

const Expertise = () => {
    const { t } = useTranslation('', {keyPrefix: 'expertise'})
    useEffect(() => {
        gsap.utils.toArray('#expertiseHeading').forEach((el) => gsap.fromTo(el, {opacity: 0, x: -400}, {
            scrollTrigger: {
                trigger: "#expertiseHeading",
                start: "top bottom",
                end: "bottom bottom-=200px",
            }, opacity: 1,
            x: 0,
            duration: 1.5,
            ease: Power2.easeInOut,
            stagger: .03
        }).delay(1))

        console.log(gsap.utils.toArray(".divider ~ *:not(.Gradientblob)"))
        gsap.utils.toArray(".expertiseContainer *:not(.GradientBlob, .attribute)").forEach(curr => {
            if (curr?.className.includes("divider")) {
                gsap.fromTo(curr, {width: 0, opacity: .3}, {
                    scrollTrigger: curr, opacity: 1,
                    width: "100%",
                    duration: 1.5,
                    delay: .5,
                })
            }else {
                gsap.fromTo(curr, {opacity: 0, y: 50}, {
                    scrollTrigger:  curr, opacity: 1,
                    y: 0,
                    delay: .5,
                    duration: 1.2,
                })
            }
        })
    }, [])
    return (
        <section className={styles.wrapper}>
            <div>
                <h2 id={"expertiseHeading"} className={styles.heading}>{t('ourExpertise')}</h2>
            </div>
            <div className={styles.grid}>
                <div className={`${styles.borderTop} expertiseContainer`}>
                    <span className={`${styles.divider} divider`}/>
                    <h3 className={styles.title + " boxTitle"}>From Concept to a real projekt</h3>
                    <p className={styles.text + " boxText"}>
                        From concept to shipment, we have you covered!
                    </p>
                    <GradientBlob animate={(el, position) => {
                        gsap.fromTo(el, {
                                opacity: 0,
                                y: 800,
                                x: -200,
                                ease: Power2.easeInOut,
                                filter: "grayscale(100%)"
                            },
                            {
                                scrollTrigger: {
                                    trigger: el,
                                    start: "top-=300px bottom",
                                    end: "bottom bottom-=400px",
                                    // markers: true
                                },
                                opacity: .4,
                                y: position.y,
                                x: position.x,
                                duration: 2,
                                filter: "blur(64px)"
                            });
                    }} hoverEffect={"blueTurqoise"} position={{y: "15%", x: "5%"}}/>
                </div>
                <div className={styles.column}>
                    <div className={`${styles.borderTop} expertiseContainer`}>
                        <span className={`${styles.divider} divider`}/>
                        <h3 className={styles.title + " boxTitle"}>{t('concept.title')}</h3>
                        <p className={styles.text + " boxText"}>
                            {t('concept.paragraph')}
                        </p>
                        <div className={styles.group}>
                            <span className={"attribute"}>{t('concept.keys.consulting')}</span>
                            <span className={"attribute"}>{t('concept.keys.ideation')}</span>
                        </div>
                    </div>
                    <div className={`${styles.borderTop} expertiseContainer`}>
                        <span className={`${styles.divider} divider`}/>
                        <h3 className={styles.title + " boxTitle"}>{t("design.title")}</h3>
                        <p className={styles.text + " boxText"}>
                            {t("design.paragraph")}
                        </p>
                        <div className={styles.group}>
                            <span className={"attribute"}>{t("design.keys.webDesign")}</span>
                            <span className={"attribute"}>{t("design.keys.appDesign")}</span>
                            <span className={"attribute"}>{t("design.keys.ux")}</span>
                            <span className={"attribute"}>{t("design.keys.ui")}</span>
                            <span className={"attribute"}>{t("design.keys.logoDesign")}</span>
                        </div>
                    </div>
                    <div className={`${styles.borderTop} expertiseContainer`}>
                        <span className={`${styles.divider} divider`}/>
                        <h3 className={styles.title + " boxTitle"}>{t("development.title")}</h3>
                        <p className={styles.text + " boxText"}>
                            {t("development.paragraph")}
                        </p>
                        <div className={styles.group}>
                            <span className={'attribute'}>{t("development.keys.frontend")}</span>
                            <span className={'attribute'}>{t("development.keys.backend")}</span>
                            <span className={'attribute'}>{t("development.keys.mobileApps")}</span>
                            <span className={'attribute'}>{t("development.keys.pwa")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Expertise